<template lang="pug">
router-link.link(v-if="isAuth && company && (company.title || slots.default)" :to="getRoute()" :target="target")
  slot {{ company.title }}

register-tooltip(
  v-else-if="!isAuth && company && (company.title || slots.default)"
  :show-analytics="true"
  :label="`Полная аналитика по ${ tab === 'suppliers' ? 'поставщику' : 'заказчику' } доступна в личном кабинете`"
)
  span.link
    slot {{ company.title }}

div(v-else) {{ emptyLabel }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import { Tabs } from "@/router/tabs";
import type { PropType } from 'vue';
import type { SimpleCompanyI } from "@/stores/manuals/ManualsInterface";

import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";
import RegisterTooltip from "~/components/other/RegisterTooltip.vue";

export default defineComponent({
  name: "AnalyticLink",
  components: {
    UiTooltip,
    RegisterTooltip,
  },
  props: {
    tab: {
      type: String as PropType<'industry'|'suppliers'|'customers'>,
      default: '',
    },
    company: {
      type: Object as PropType<SimpleCompanyI>,
      default: () => ({}),
    },
    needThisOffice: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String as PropType<'_self'|'_blank'>,
      default: '_blank',
    },
    emptyLabel: {
      type: String,
      default: 'Нет данных',
    },
  },
  setup(props, { slots }) {

    const router = useRouter();

    const { isAuth } = useUserAccess();

    function getRouteParam(tab: 'industry'|'suppliers'|'customers') {
      switch(tab) {
        case 'industry': return 'lots';
        case 'suppliers': return 'won';
        case 'customers': return 'completed';
      }
    }

    function getRouteName(tab: 'industry'|'suppliers'|'customers') {
      switch(tab) {
        case 'industry': return Tabs.Analytics.Industry;
        case 'suppliers': return Tabs.Analytics.Suppliers;
        case 'customers': return Tabs.Analytics.Customers;
      }
    }

    function getRoute() {
      return router.resolve({ name: getRouteName(props.tab), params: { subTab: getRouteParam(props.tab) }, query: {
          id: props.company?.id || undefined,
          inn: props.company?.inn || undefined,
          kpp: props.company?.kpp || undefined,
          needThisOffice: props.needThisOffice,
      }})
    }

    return {
      slots,
      isAuth,
      getRoute,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}
</style>
