<template lang="pug">
ui-tooltip(placement="bottom" shadow close-on-click trigger="click")
  slot
  template(#content)
    .advertisement
      span.label(v-if="label") {{ label }}
      span.description(v-if="description") {{ description }}
      ui-button.analytic-button(@click="showDialog('registration')") Получить полный доступ <br /> на 7 дней бесплатно
      span.link(v-if="showAnalytics" @click="scrollToAnalytics") Возможности аналитики
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useLandingLogin } from "~/use/auth/useLandingLogin";

import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import { useRouter } from "vue-router";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { Tabs } from "~/router/tabs";

export default defineComponent({
  name: "RegisterTooltip",
  components: {
    UiButton,
    UiTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    showAnalytics: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const router = useRouter();

    const { isAuth } = useUserAccess();
    const { showDialog } = useLandingLogin();
    const { storage } = useServiceStorage();

    function scrollToAnalytics() {
      storage.scrollIntoAnalytic = true;
      router.push({ name: Tabs.Landing })
    }

    return {
      isAuth,
      scrollToAnalytics,
      showDialog,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.advertisement {
  display: flex;
  flex-flow: column;
  gap: 16px;
  text-align: center;
  max-width: 360px;
  color: var(--main-color-black);
  font-weight: normal;

  .label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .description {
    font-size: 13px;
    line-height: 18px;
  }

  .link {
    color: var(--default-blue-color);
    cursor: pointer;

    font-size: 12px;
    line-height: 16px;
  }
}

@media(max-width: 640px) {
  .advertisement {
    gap: 8px;
    max-width: 280px;

    .label {
      font-size: 14px;
      line-height: 20px;
    }

    .description {
      font-size: 12px;
      line-height: 16px;
    }

    .link {
      font-size: 11px;
      line-height: 16px;
    }
  }
}
</style>
