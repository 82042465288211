import { useApi } from "@/use/api/useApi";
import { generateId } from "@/utils/uniqueGenerator/generator";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { useExcelDownloader } from "@/use/other/useExcelDownloader";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import type { Ref } from "vue";

enum nameFirst {
  'lots' = 'Аналитика по закупкам',
  'customers' = 'Аналитика по заказчикам',
  'suppliers' = 'Аналитика по поставщикам',
  'won' = 'Аналитика по победам',
  'participated' = 'Аналитика по участиям',
  'competitors' = 'Аналитика по конкурентам',
  'actual' = 'Аналитика по актуальным закупкам',
  'completed' = 'Аналитика по завершенным закупкам',
  'participants' = 'Аналитика по участникам',
}

enum nameSecond {
  'industry' = ' отрасли',
  'suppliers' = ' поставщика',
  'customers' = ' заказчика',
}

/** Скачивание результатов аналитика */
export function downloadAnalyticExcel(loader: Ref<boolean>, data: any, mode: string, tab: 'industry'|'suppliers'|'customers', title: string) {
  const downloadRoute = `analytics/${tab}/${mode}/download`;
  const fileName = nameFirst[mode] + nameSecond[tab] + ' ' + title;

  downloadFromBackend(loader, downloadRoute, data, fileName);
}

/** Скачивание результатов поиска/аналитики с бека - напрямую используется в поиске, заявках и контрактах */
export function downloadFromBackend(loader: Ref<boolean>, downloadRoute: string, payload: any, fileName: string) {
  const {
    excelFileName,
    excelDownloadLoader,
  } = useExcelDownloader();

  excelFileName.value = `${ fileName } от ${ formatDateTime(new Date(), '-') }.xlsx`

  loader.value = true
  excelDownloadLoader.value = true

  useApi().files.fetchFileDownload(downloadRoute, payload)
    .then((data) => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(data)
      link.download = excelFileName.value
      link.click()
    })
    .catch(() => {
      useNotificationsStore().showError('Произошла ошибка при скачивании файла')
    })
    .finally(() => {
      loader.value = false;
      excelDownloadLoader.value = false;
    })
}

/** Скачивание формы расчета */
export function downloadFormCalculation(loader: Ref<boolean>, id: number, govRuId: string) {

  const {
    excelFileName,
    excelDownloadLoader,
  } = useExcelDownloader();

  excelFileName.value = `Паспорт расчета №${ govRuId }.xlsx`;

  loader.value = true;
  excelDownloadLoader.value = true

  useApi().files.fetchFormCalculation(id)
    .then((data) => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(data)
      link.download = excelFileName.value
      link.click()
    })
    .catch(() => {
      useNotificationsStore().showError('Произошла ошибка при скачивании файла')
    })
    .finally(() => {
      loader.value = false;
      excelDownloadLoader.value = false;
    });
}

/** Скачивание внешних файлов */
export function downloadFromLink(link: string) {
  const frameId = generateId(5)
  const iframe = document.createElement('iframe')
  iframe.src = link;
  iframe.style.display = 'none'
  iframe.id = frameId
  document.body.appendChild(iframe)

  if (iframe.contentWindow!.document.readyState == 'complete') {
    setTimeout(() => {
      document.body.removeChild(document.getElementById(frameId)!)
    }, 10000)
  }
}
